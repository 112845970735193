(function ($) {
    class VideoSelection {
        constructor() {
            this.$selected_video_con = $(".selected-video");
            this.$selected_video_iframe = this.$selected_video_con.find(".video-iframe");
            this.$video_selections = $(".video-selections .video-selection");

            this.bindUI();
            this.setFirst();
        }

        bindUI() {
            let that = this;

            this.$video_selections.on("click", function () {
                that.setVideo($(this));
            });
        }

        setVideo($video, animate = true) {
            let that = this;
            this.$video_selections.removeClass("active");
            $video.addClass("active");
            this.$selected_video_iframe.html($video.find(".video-iframe").val());

            //title
            let title = $video.find('.video-title .title-wrap').html();
            this.$selected_video_con.find('.title-wrap').html(title);

            //category
            let category = $video.find('.category').html();
            this.$selected_video_con.find('.category').html(category);

            //duration
            let duration = $video.find('.duration').html();
            this.$selected_video_con.find('.duration').html(duration);

            if(animate){
                $('html, body').animate({
                    scrollTop: that.$selected_video_con.offset().top - $('#main_header').height()
                }, 500);
            }
        }

        setFirst() {
            this.setVideo(this.$video_selections.first(), false);
        }
    }

    new VideoSelection();

}(jQuery));