//img popup
class ImgPopup {
    constructor(container) {
        this._container = container;
        this.bindUI();
    }

    static init() {
        let img_popup = document.querySelectorAll('.img-popup');

        if (!img_popup.length) {
            return;
        }

        for (let i = 0; i < img_popup.length; ++i) {
            new ImgPopup(img_popup[i]);
        }
    }

    bindUI() {
        this._container.onclick = (e) => {
            e.preventDefault();
            let src = this._container.getAttribute("href");

            let img = document.createElement("img");
            img.src = src;

            basicLightbox.create(img.outerHTML).show();
        };
    }
}

ImgPopup.init();