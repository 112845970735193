(function ($) {
    class Faqs {
        constructor(container) {
            this._container = container;
            this.bindUI();
        }

        bindUI() {
            let faq_modules = this._container.querySelectorAll('.faq-module');
            let $faq_modules = $(faq_modules);

            if (faq_modules.length) {
                for (let i = 0; i < faq_modules.length; ++i) {
                    let module = faq_modules[i];

                    module.onclick = () => {
                        let $module = $(module);
                        let answer = $module.find(".answer");

                        let isOpen = $module.hasClass("active");

                        $faq_modules.removeClass("active");
                        $faq_modules.find(".answer").slideUp();

                        if (!isOpen) {
                            answer.slideToggle();
                            $module.toggleClass("active");
                        }
                    };
                }
            }
        }


        static init() {
            let faqs_modules = document.querySelectorAll('.faqs-modules');

            for (let i = 0; i < faqs_modules.length; ++i) {
                new Faqs(faqs_modules[i]);
            }
        }
    }

    Faqs.init();


}(jQuery));
