(function ($) {
//class responsible for the search overlay
    class TabContentControl {
        constructor(con) {
            this._con = con;
            this.tab_nav = this._con.querySelectorAll(".tab-nav");

            if (!this.tab_nav)
                return;

            let default_nav_id = false;


            //go through navigation
            for (let c = 0; c < this.tab_nav.length; c++) {
                let tab_nav = this.tab_nav[c];

                //on click add active class
                tab_nav.addEventListener('click', (e) => {
                    let tab_id = tab_nav.getAttribute("data-tab-id");
                    this.setActive(tab_id);
                })

                if (tab_nav.classList.contains("default")) {
                    default_nav_id = tab_nav.getAttribute("data-tab-id")
                }
            }

            //set default active
            if (default_nav_id) {
                this.setActive(default_nav_id);
            } else {
                //set first
                this.setActive(this.tab_nav[0].getAttribute("data-tab-id"));
            }
        }

        setActive(tab_id) {
            $(this._con).trigger("active_tab");

            //find tab content with same id
            let tab_content = this._con.querySelectorAll(".tab-content");
            let all_tab = this._con.querySelectorAll(".tab-nav");

            //add active state
            for (let c = 0; c < tab_content.length; c++) {
                let content = tab_content[c];

                let content_tab_id = content.getAttribute("data-tab-id");

                if (content_tab_id === tab_id) {
                    content.classList.add("active");
                } else {
                    content.classList.remove("active");
                }
            }

            //add active state
            for (let c = 0; c < all_tab.length; c++) {
                let tab = all_tab[c];
                tab.classList.remove("active");

                let nav_tab_id = tab.getAttribute("data-tab-id");

                if (nav_tab_id === tab_id) {
                    tab.classList.add("active");
                } else {
                    tab.classList.remove("active");
                }
            }

        }
    }

    class TabContent {
        static init() {
            this.tabs_con = document.querySelectorAll(".tab-container")

            if (this.tabs_con === undefined || !this.tabs_con || this.tabs_con.length === 0)
                return;


            //go through each tab set
            for (let c = 0; c < this.tabs_con.length; c++) {
                let con = this.tabs_con[c];
                new TabContentControl(con);
            }
        }
    }

    TabContent.init();


}(jQuery));
