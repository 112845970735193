(function ($) {
    $('.hero-slider').each(function () {
        let module_length = $(this).children().length;

        $(this).slick({
            autoplay: true,
            autoplaySpeed: 6000,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: module_length > 1,
            arrows: false,
            fade: true
        });
    });

    $('.video-testimonials').each(function () {
        let module_length = $(this).find(".video-testimonial").length;
        $(this).slick({
            infinite: false,
            arrows: false,
            variableWidth: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: module_length > 3,
            responsive: [
                {
                    breakpoint: 1137,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: module_length > 3,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true
                    }
                }, {
                    breakpoint: 610,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true
                    }
                }
            ]
        });
    });

    $('.tab-product-slider').slick({
        responsive: [
            {
                breakpoint: 4000,
                settings: "unslick"
            },
            {
                breakpoint: 769,
                settings: {
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                }
            }
        ]
    });


    $('.modules-list.module-fullwidth-slider').slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    dots: true
                }
            },
            {
                breakpoint: 1140,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true
                }
            }, {
                breakpoint: 568,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    });

    $('.fullwidth-arrow-slider').each(function () {
        const slides = $(this).find('.bio-module');
        const hasLimit = slides.length >= 5;

        let args = {
            autoplay: true,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            nextArrow: '<div class="next-arrow far fa-angle-right"></div>',
            prevArrow: '<div class="prev-arrow far fa-angle-left"></div>',
            responsive: [{
                breakpoint: 568,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    variableWidth: true,
                    centerMode: true,
                    centerPadding: '60px',
                }
            }]
        };

        if (hasLimit) {
            args.responsive.push({
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false,
                    variableWidth: true,
                    centerMode: true,
                    centerPadding: '60px',
                }
            });

        }else{
            args.responsive.push({
                breakpoint: 1140,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    variableWidth: true,
                    centerMode: true,
                    centerPadding: '60px',
                }
            });
        }
        $(this).slick(args);
    });

    let $specialty_slider =  $('.specialty-modules .slider');
    let specialty_slider_settings = {
        centerMode: true,
        centerPadding: '0',
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: "unslick"
            },
            {
                breakpoint: 700,
                settings: {
                    arrows: false,
                    dots: true,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1,
                    variableWidth: true
                }
            }
        ]
    };
    $specialty_slider.slick(specialty_slider_settings);

    // Reslick only if it's not slick()
    $(window).on('resize', function() {
        if (!$specialty_slider.hasClass('slick-initialized')) {
            return $specialty_slider.slick(specialty_slider_settings);
        }
    });

    $('.evidence-slider .slider').each(function () {
        $(this).slick({
            slidesToShow: 4,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        arrows: false,
                        dots: true,
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 1,
                        variableWidth: true
                    }
                }
            ]
        });
    });


    $('.news-slider .slider').each(function () {
        $(this).slick({
            slidesToShow: 3,
            arrows: false,
            dots: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        arrows: false,
                        dots: true,
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 1,
                        variableWidth: true
                    }
                }
            ]
        });
    });

    $('.story-slider .slider').each(function () {
        let module_length = $(this).find(".bio-module").length;
        if (module_length > 3) {
            $(this).slick({
                infinite: false,
                arrows: false,
                slidesToShow: 4,
                slidesToScroll: 4,
                dots: module_length > 3,
                responsive: [
                    {
                        breakpoint: 1137,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            dots: true
                        }
                    }, {
                        breakpoint: 568,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true
                        }
                    }
                ]
            });
        }
    });

    $('.modules-list.module-default-slider').each(function () {
        let module_length = $(this).find(".bio-module").length;
        if (module_length > 2) {
            $(this).slick({
                infinite: false,
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: module_length > 2,
                responsive: [
                    {
                        breakpoint: 1137,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            dots: true
                        }
                    }, {
                        breakpoint: 568,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true
                        }
                    }
                ]
            });
        }
    });


    $('.modules-list.module-centered-slider').each(function () {
        let module_length = $(this).find(".bio-module").length;

        $(this).slick({
            centerMode: true,
            centerPadding: '60px',
            slidesToShow: 1,
            arrows: true,
            dots: true,
            variableWidth: true,
            nextArrow: '<div class="next-arrow far fa-angle-right"></div>',
            prevArrow: '<div class="prev-arrow far fa-angle-left"></div>',
            infinite: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        arrows: false,
                        centerMode: false,
                        centerPadding: '10px',
                        slidesToShow: 2,
                        variableWidth: false,
                    }
                }, {
                    breakpoint: 660,
                    settings: {
                        arrows: false,
                        centerMode: false,
                        centerPadding: '10px',
                        slidesToShow: 1,
                        variableWidth: false,
                    }
                },
            ]
        });
    });

    $('.modules-list.module-half-slider').each(function () {
        let module_length = $(this).find(".bio-module").length;

        if (module_length === 1) {
            return;
        }

        $(this).slick({
            slidesToShow: 2,
            arrows: true,
            dots: true,
            nextArrow: '<div class="next-arrow far fa-angle-right"></div>',
            prevArrow: '<div class="prev-arrow far fa-angle-left"></div>',
            infinite: true,
            responsive: [
                {
                    breakpoint: 935,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                    }
                },
            ]
        });
    });


}(jQuery));