(function ($) {
//focus inputs active state
    class FocusInputs {
        constructor($input_con) {
            this.input_con = $input_con;
            this.input_field = $input_con.find(".input-field");

            this.bindUI();
        }

        onFocus() {
            this.input_con.addClass("focus");
        }

        onBlur() {
            this.input_con.removeClass("focus");
        }

        bindUI() {
            let that = this;
            this.input_field.on("focus", function () {
                that.onFocus();
            });
            this.input_field.on("blur", function () {
                that.onBlur();
            });
        }

        static init() {
            let inputs = jQuery(".input-icon-outline");
            if (!inputs.length) {
                return;
            }

            inputs.each(function () {
                new FocusInputs($(this));
            });
        }
    }

    FocusInputs.init();

}(jQuery));