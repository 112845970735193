//sliders
(function ($) {
    function is_touch_device() {
        var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        var mq = function(query) {
            return window.matchMedia(query).matches;
        }

        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    }

    if(is_touch_device()){
        $('body').addClass('touch');
    }


    //menu button toggle
    $("#menu_btn").click(function () {
        jQuery("body").toggleClass("overlay-menu-open")
    });

    $("#overlay_nav .menu-item-has-children > a").click(function (e) {
        e.preventDefault();

        let parent = jQuery(this).parent();
        parent.toggleClass("active-li");
        parent.find('.sub-menu').slideToggle();
    });

    $("#main_nav>ul>li.menu-item-has-children").click(function (e) {
        if (e.target === jQuery(this).find("a:first-child")[0]) {
            return;
        }
        jQuery(this).find(".sub-menu").toggleClass("active-li").slideToggle();
    });

    //dropdown-link-menu
    let off_body = function (e) {
        let $e = $(e.target);

        if (!$e.closest(".dropdown-link-menu").length && !$e.hasClass(".dropdown-link-menu")) {
            $(".dropdown-link-menu").removeClass("active");
            $('body').off("click", off_body);
        }
    };
    $(".dropdown-link-menu").on("click", function () {
        $(this).addClass("active");
        $("body").on("click", off_body)
    });

    let counter = 1;

    let subTrigger = $('.menu-item-has-children > a')



    subTrigger.click(function (e) {

        if ( $(window).width() >= 992 && $(window).width() <= 1025){
            e.preventDefault();

            console.log( "default " + event.type + " prevented "+counter );
            if(counter === 1) {
                console.log( "now enable click" );
                $(this).unbind("click");
            }

            $('.sub-menu').removeClass('active-li');

            if ( jQuery(this).next().hasClass("active-li") ) {

                jQuery(this).next().removeClass("active-li");
            } else {
                jQuery(this).addClass('linkable');
                jQuery(this).next().addClass("active-li") ;
            }
        }
    });

    $( window ).scroll(function() {
        if ( $(window).width() >= 992 && $(window).width() <= 1025) {

            console.log('do shit');
            $(".sub-menu").removeClass("active-li");
        }
    });




    //scroll to animation
    $('[data-scroll-to], a.scroll-to').click(function (e) {
        e.preventDefault();
        let el_id = $(this).data("scroll-to");
        if (!el_id) {
            el_id = $(this).attr("href");
        }

        if (!el_id)
            return;

        $('html, body').animate({
            scrollTop: $(el_id).offset().top - $('#main_header').height()
        }, 500);
    });

    //ninja forms country
    jQuery(document).on( 'nfFormReady', function( e, layoutView ) {

        $(document).on("change",'.contact-us-form select.country-input', function(){
            let con = $(".contact-us-form .state-input-con");
            if($(this).val() === "US"){
                con.find('.state-input').val('');
                con.show();
            }else{
                con.find('.state-input').val('none');
                con.hide();
            }
        });
        $(".contact-us-form select.country-input").change();

    });

}(jQuery));

